.cdk-overlay-backdrop-white {
    background-color: white;
    position: absolute;
}

.cdk-overlay-dark-backdrop{
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(0,0,0,0.13);
}
.cdk-box{
    height: 100vh;
    position: relative;
    overflow: scroll;
    background-color: white;
}

.carStyleWithBrandModal{
  top: 50px;
}

