/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "animate.css/animate.css";


@import "component";
@import "app/styles/ant";
@import "app/styles/cdk";
@import "app/styles/component";
body{
  font-size: 16px;
}
.item-input.sc-ion-label-ios-h, .item-input .sc-ion-label-ios-h{
  max-width: none;
  flex: auto;
  font-size: 14px;
}
.item-input.sc-ion-label-md-h, .item-input .sc-ion-label-md-h{
  max-width: none;
  flex: auto;
  font-size: 14px;
}
ion-datetime:after{
  content: '▼';
}
ion-picker-column.picker-col:nth-child(2){
  flex: 1.5;
}

ion-picker-column  .picker-opt{
  font-size: 14px;
}

html{font-size:62.5%}
.head-home{
  font-size:20px;
  margin-right: 6px;
  ion-icon{
    height: 30px;
    width: 30px;
  }
}
.modal-top-1{
  padding-top: 7rem;
  background-color: rgba(255,255,255,0.3);
}
.item-label{

}
.item-row{
  font-size: 17px;
  font-weight: 800;
  border-bottom: 1px solid grey;
  margin-bottom: 4px;
  padding-top: 7px;
  padding-bottom: 7px;
}

ion-item{
  font-size: 14px;
}

.w-3rem{
  width: 3rem !important;
}
.w-4rem{
  width: 4rem !important;
}
.w-5rem{
  width: 5rem !important;
}
.w-6rem {
  width: 6rem !important;
}
.w-5rem {
  width: 5rem !important;
}

.w-7rem {
  width: 7rem !important;
}
.w-8rem {
  width: 8rem !important;
}
.w-9rem {
  width: 9rem !important;
}
.w-10rem {
  width: 10rem !important;
}
.w-11rem {
  width: 11rem !important;
}
.w-12rem {
  width: 12rem !important;
}
.w-13rem {
  width: 13rem !important;
}
.w-14rem {
  width: 14rem !important;
}


.arrow-bottom {
  font-size: 0;
  line-height: 0;
  border-width: 5px;
  border-color: grey;
  border-bottom-width: 0;
  border-style: dashed;
  border-top-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;
}
.font-size-20px{
  font-size: 20px;
}
.flex-w-1,flex-1{
  flex: 1;
}

.text-bootstrap-info{
  --bs-text-opacity: 1;
  color: rgba(13,202,240,1)!important;
}

/* 浅色模式下的样式 */
body {
  color: #000000;        /* 文字颜色 */
  background-color: #ffffff;  /* 背景颜色 */
}

/* 深色模式下的样式 */
@media (prefers-color-scheme: dark) {
  body {
    color: #ffffff;        /* 文字颜色 */
    background-color: #000000;  /* 背景颜色 */
  }
}


.ant-picker-footer .ant-picker-ranges{
  display:flex;


}

.ant-picker-now{
  flex:1;
}
.ant-picker-ranges .ant-picker-ok{
  flex:1;
  float:none;


}

.ant-picker-time-panel-column{
  width:auto;
}
.ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner{
  padding: 2px;
}
