

.sys-company-select-filter-cdk-box {
  font-size: 18px;
  .cdk-body{
    padding: 5px;
  }
  .card{
    margin-bottom: 2px;
  }
  .cdk-title{
    background-color: var(--app-danger);
    padding: 5px;
    color: white;
    font-size: 14px;
    ion-icon{
      font-size: 12px;
      height: 24px;
      width: 24px;
      float: right;
    }
  }
  .cdk-footer{
    position: fixed;
    bottom: 12px;


    width: 100%;
    .cdk-footer-inner{
      .cdk-footer-buttons{
        flex-direction: row;
        display: flex;
      }
    }
    ion-button{
      flex: 1;

    }
  }

  .select{
    border: 1px solid red;
  }
  .box {
    .box-title {
      font-size: 13px;
      padding: 5px;
    }
    border-bottom:1px solid rgba(0,0,0,0.13);
  }
  .box-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .list-item-box {
      width: 33%;
      text-align: center;
      padding: 2px;

      .list-item {
        border: 1px solid rgba(0, 0, 0, 0.13);
        font-size: 15px;
      }
      .list-item-active {
        border: 2px solid rgba(255, 0, 0, 0.13);
        color: red;
        background-color: rgba(255, 0, 0, 0.13);
      }
    }
  }
}

