ion-popover {
  --background: rgba(40, 173, 218, 0.6);
  --backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --color: white;
  --width: 100%;
}
.company-search-component-ion-padding{
  --background: rgba(40, 173, 218, 0.6);
  --backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --color: white;
  //--width: 100%;
  --width: 800px;
  display: inline-block;
}
