
$ant-prefix:ant;
$message-prefix-cls:#{ant}-message;
$zindex-message:1001;
$message-notice-content-padding:10px 16px;
$message-notice-content-bg:#fff;
$border-radius-base:2px;
$shadow-2: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
0 9px 28px 8px rgba(0, 0, 0, 0.05);
$iconfont-css-prefix: anticon;
$success-color: #198754;
$error-color:#dc3545;
$warning-color:#ffc107;
$info-color:#0dcaf0;
$font-size-lg:20px;
$text-color:#000;
$font-size-base:20px;
$font-variant-base: tabular-nums;
$line-height-base:1.5715;
$font-feature-settings-base: 'tnum';
.#{$message-prefix-cls} {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $text-color;
  font-size: $font-size-base;
  font-variant: $font-variant-base;
  line-height: $line-height-base;
  list-style: none;
  font-feature-settings: $font-feature-settings-base;
  position: fixed;
  top: 16px;
  left: 0;
  z-index: $zindex-message;
  width: 100%;
  pointer-events: none;
  &-notice {
    padding: 8px;
    text-align: center;
    width: 100%;
    &:first-child {
      margin-top: -8px;
    }
  }
  &-notice-content {
    display: inline-block;
    position: relative;
    padding: $message-notice-content-padding;
    background: $message-notice-content-bg;
    border-radius: $border-radius-base;
    box-shadow: $shadow-2;
    pointer-events: all;
    width: 90%;
  }

  &-success {
    color: $success-color;
  }

  &-error  {
    color: $error-color;
  }

  &-warning  {
    color: $warning-color;
  }

  &-info ,
  &-loading  {
    color: $info-color;
  }

  .#{$iconfont-css-prefix} {
    position: relative;
    top: 1px;
    margin-right: 8px;
    font-size: $font-size-lg;
  }

  &-notice.move-up-leave.move-up-leave-active {
    animation-name: MessageMoveOut;
    animation-duration: 0.3s;
  }
  &-notice-close{
    position: absolute;
    right: -10px;
    top:-10px;
    font-size: 3rem;
    .second{
      font-size: 1rem;
      position: absolute;
      top: -5px;
      right: -1px;
    }
  }
}

@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

