.city-content-box {
  position: relative;
  overflow: scroll;
  height: 100vh;
  //width: 100vh;
  background-color: white;
  .search-box {
    padding-right: 2rem;
  }

  .hot-city-box {
    padding-right: 2.5rem;
    padding-left: 2rem;

    .hot-city-header {
      font-size: 1.5rem;
    }

    .hot-city-body {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      .city-item-wrap {
        width: 12%;

        .city-item {
          text-align: center;
          background-color: rgba(0, 0, 0, 0.09);
          padding: 3px;
          margin: 2px;
          font-size: 1.5rem;
        }

        .city-item:active {
          color: red;
        }
      }

    }
  }

  .city-box {
    padding-right: 2.5rem;
    padding-left: 2rem;

    .city-header {
      font-size: 1.5rem;
      margin-top: 1rem;
      margin-bottom: 0px;
    }

    .city-body {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      .city-item-wrap {
        width: 33%;

        .city-item {
          text-align: center;
          font-size: 1.5rem;
          background-color: rgba(0, 0, 0, 0.09);
          padding: 3px;
          margin: 2px;
        }

        .city-item:active {
          color: red;
        }
      }

    }
  }

  .current-city {
    color: red;
  }

  .list-shortcut {
    display: block;
    position: fixed;
    top: 60px;
    right: 12px;
    padding: 2px;
    text-align: center;

    ul, li {
      list-style: none;
    }

    .list-current-letter {
      color: red;
    }

    li {
      font-size: 1.7rem;

    }

    li:active {
      color: red;
    }
  }

}
@keyframes example {
  0%   {background-color:red; left:0px; top:0px;}
  25%  {background-color:yellow; left:200px; top:0px;}
  50%  {background-color:blue; left:200px; top:200px;}
  75%  {background-color:green; left:0px; top:200px;}
  100% {background-color:red; left:0px; top:0px;}
}
